import React, { PropsWithChildren, useState } from 'react'
import './App.css'
import { Web3Provider } from '@ethersproject/providers'
import { hashMessage, recoverAddress } from 'ethers/lib/utils'
import { getAuth } from './services/api.service'
import { getWallet } from './services/wallet.service'
import { STOY } from 'src/services/contract.service'

const Auth = ({ children }: PropsWithChildren) => {
  const [ auth, setAuth ] = useState(getAuth())

  const connectWallet = async () => {
    const wallet = getWallet()
    if (!wallet) {
      setTimeout(() => {
        alert('메타마스크를 설치해주세요.')
      })

      return
    }

    await wallet.request({ method: 'eth_requestAccounts' })

    const provider = new Web3Provider(wallet)
    const message = '본 페이지는 ERC20 관리 기능을 지원하기 위해 제공됩니다.\n\n' +
      '서명은 사용자 인증을 위해 사용되며, 가스를 사용하지 않습니다.\n\n' +
      'made by DARKO Studio'
    const signature = await provider.getSigner().signMessage(message)
    const digest = hashMessage(message)
    const auth = { digest, signature }

    const address = recoverAddress(auth.digest, auth.signature)
    const signer = provider.getSigner()
    if (address === await signer.getAddress()) {
      const contract = STOY()
      const owner = await contract.owner()
      if (address === owner)
        setAuth(auth)
      else
        alert('Owner 지갑주소가 아닙니다')
    }
  }

  if (auth)
    return (<>{children}</>)

  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={ connectWallet }
          className="px-3 md:px-4 w-36 h-10 md:w-48 xl:w-60 rounded-md h-fit justify-center items-center"
        >
          <img src={ "/images/MetaMask_Fox.svg" } alt={ 'metamask icon' } className={ 'w-fit' } />
          <span className="px-1 md:px-2 text-base md:text-xl xl:text-3xl font-label">{ '메타마스크 연결' }</span>
        </button>
      </header>
    </div>
  )
}

export default Auth

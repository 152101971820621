import React, { useEffect, useState } from 'react'
import { BigNumber, Contract, utils } from 'ethers'
import { SubmitButton } from 'src/SubmitButton'
import { STOY, toMinimalUnit, toUnit } from 'src/services/contract.service'

export const Account: React.FC<{ network: string }>
  = ({ network }) => {

  const [ contract, setContract ] = useState<Contract>()
  const [ decimals, setDecimals ] = useState<number>()
  const [ account, setAccount ] = useState('')
  const [ balance, setBalance ] = useState<number>()
  const [ locked, setLocked ] = useState<number>()
  const [ timeLock, setTimeLock ] = useState<any[]>()
  const [ balanceAvailable, setBalanceAvailable ] = useState<number>()
  const [ isPaused, setPaused ] = useState(false)

  const [ timeLockTime, setTimeLockTime ] = useState('')
  const [ timeLockAmount, setTimeLockAmount ] = useState('')
  const [ lockAmount, setLockAmount ] = useState('')

  const [ status, setStatus ] = useState<{ [key: number]: 'none' | 'pending' | 'finished' }>({
    0: 'none',
    1: 'none',
  })

  useEffect(() => {
    try {
      const contract = STOY()
      setContract(contract)
      setTimeout(async () => {
        setDecimals(await contract.decimals())
      })
    } catch (e) {
      console.error(e)
    }
  }, [ network ])

  useEffect(() => {
    if (contract && account !== '') {
      setTimeout(async () => {

      })
    }
  }, [ contract, account ])

  const submit = (index: number) => {
    if (!contract || !decimals) return
    if (!utils.isAddress(account)) return

    if (index === 0) {
      setStatus({
        ...status,
        0: 'pending'
      })
      setTimeout(async () => {
        const p = await contract.isPaused(account)
        setPaused(p)
        const b = await contract.balanceOf(account)
        const balance_ = toUnit(b, decimals)
        setBalance(balance_)
        const l = await contract.lockedBalanceOf(account)
        const locked_ = toUnit(l, decimals)
        setLocked(toUnit(l, decimals))

        let total = balance_ - locked_

        const currentTimestamp = Date.now() / 1000

        const [ times, amounts ] = await contract.timeLockInfoOf(account)
        const timeLockInfo = []
        for (let i = 0; i < times.length; i++) {
          const timestamp = times[i].toNumber()
          const timeLockAt = new Date(timestamp * 1000)

          if (timestamp > currentTimestamp) {
            const amount = toUnit(amounts[i] as BigNumber, decimals)
            total -= amount
            timeLockInfo.push(
              [ timeLockAt.toLocaleString('en-CA',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  hourCycle: 'h24',
                  minute: '2-digit',
                  second: '2-digit',
                  timeZone: 'Asia/Seoul'
                }), amount ]
            )
          }
        }

        if (timeLockInfo.length > 0) {
          setTimeLock(timeLockInfo)
        }

        if (total < 0) total = 0
        setBalanceAvailable(total)

        setStatus({
          ...status,
          0: 'none'
        })
      })
    }
  }

  const onChangeAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (utils.isAddress(value))
      setAccount(value)
  }

  // const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target
  //
  //   setValues(values => {
  //     return {
  //       start: values.start,
  //       end: parseInt(value),
  //     }
  //   })
  // }

  const accountButtonTitle = (status: string, index: number) => {
    let title
    if (status === 'none')
      title = '조회'
    else if (status === 'pending')
      title = '조회 중 🧾'

    return title
  }

  //           {'isPaused'}
  //           {'pauseAccount'}
  //           {'unpauseAccount'}
  //
  //           {'lockedtokenNameOf'}
  //           {'lock'}
  //           {'unlock'}
  //
  //           {'timeLockInfoOf'}
  //           {'timeLock'}
  //           {'deleteTimeLock'}

  const showAmount = (amount: number | undefined) => {
    return amount ? Intl.NumberFormat().format(amount) + ' STOY' : ''
  }

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">지갑주소 정보</h3>
            </div>
          </div>
          <div className="mt-5">
            <form onSubmit={ (e) => {
              e.preventDefault()
            } }>
              <div className="overflow-hidden shadow rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="account" className="block text-sm font-medium text-gray-700">
                        주소
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="account"
                             name="account" defaultValue={ account } onChange={ onChangeAccount } />
                      {/*<p className={ 'px-2 py-1 text-xs' }>{ (new Date(values.start * 1000)).toString() }</p>*/ }
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-2">
                  { contract ?
                    (
                      <>
                        <SubmitButton status={ status } index={ 0 } setTitle={ accountButtonTitle }
                                      updateStatus={ submit } />
                      </>
                    ) : null
                  }
                </div>
              </div>

              <div className="mt-5 overflow-hidden shadow rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-6">
                      { isPaused ?
                        <span className={ 'float-right inline-block p-2 rounded-lg bg-red-900 font-bold text-white' }>정지된 지갑주소</span> : '' }
                      <p>보유 잔고</p>
                      <p className={ 'p-4' }>{ showAmount(balance) }</p>
                      <p>락업을 제외한 잔고</p>
                      <p className={ 'p-4' }>{ showAmount(balanceAvailable) }</p>
                      <p>무기한 락업</p>
                      <p className={ 'p-4' }>{ showAmount(locked) }</p>
                      <p>시간제한 락업</p>
                      <p className={ 'p-4' }>
                        { timeLock ? timeLock.map((info) => {
                          return (
                            <span
                              key={ info[0] }>~ { info[0] } { '==>' } { Intl.NumberFormat().format(info[1]) } STOY<br /></span>
                          )
                        }) : '' }
                      </p>
                    </div>

                  </div>
                </div>
              </div>

              <div className="mt-10 md:col-span-2">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6">관리 명령</h3>
                </div>
              </div>

              <div className="mt-5 overflow-hidden shadow rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="timeLock" className="block text-sm font-medium text-gray-700">
                        락업 해제 시각
                      </label>
                      <input className={ 'block rounded-md w-full' } type="datetime-local" id="timeLockTime"
                             name="timeLockTime" defaultValue={ timeLockTime }
                             onChange={ e => setTimeLockTime(e.target.value) } />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="timeLockAmount" className="block text-sm font-medium text-gray-700">
                        수량 (STOY)
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="timeLockAmount"
                             name="timeLockAmount" defaultValue={ timeLockAmount }
                             onChange={ e => setTimeLockAmount(e.target.value) } />
                    </div>

                  </div>
                </div>
                { (timeLockTime && timeLockAmount) ?
                  <div className="flex bg-gray-50 px-4 py-3 sm:px-6 space-x-2">
                    <p className={ 'flex-grow px-2 py-1' }>
                      11. timeLock (0x7aa98fb3)<br />
                      timestamp_ = { Date.parse(timeLockTime) / 1000 }<br />
                      amount_ = {toMinimalUnit(timeLockAmount, decimals)}
                    </p>
                    <a
                      className={'flex-1 inline-flex items-center'}
                      target={'_blank'}
                      href='https://etherscan.io/token/0xb292B3aB38883CFdb70B7514Ab58f6d1D8297d40#writeContract#F11'>
                      <button type="button" className={'bg-indigo-600 hover:bg-indigo-700 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'}>링크</button>
                    </a>
                  </div>
                  : ''
                }
              </div>

              <div className="mt-5 overflow-hidden shadow rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="lockAmount" className="block text-sm font-medium text-gray-700">
                        무기한 락업 수량 (STOY)
                      </label>
                      <input className={ 'block rounded-md w-full' } type="text" id="lockAmount"
                             name="lockAmount" defaultValue={ lockAmount }
                             onChange={ e => setLockAmount(e.target.value) } />
                    </div>

                  </div>
                </div>
                { (lockAmount) ?
                  <div className="flex bg-gray-50 px-4 py-3 sm:px-6 space-x-2">
                    <p className={ 'flex-grow px-2 py-1' }>
                      7. lock (0x282d3fdf)<br />
                      amount_ = {toMinimalUnit(lockAmount, decimals)}
                    </p>
                    <a
                      className={'flex-1 inline-flex items-center'}
                      target={'_blank'}
                      href='https://etherscan.io/token/0xb292B3aB38883CFdb70B7514Ab58f6d1D8297d40#writeContract#F7'>
                      <button type="button" className={'bg-indigo-600 hover:bg-indigo-700 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'}>링크</button>
                    </a>
                  </div>
                  : ''
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import 'src/App.css'
// import { DeployContract } from 'src/DeployContract'
// import { SetAllowList } from 'src/SetAllowList'
import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import { logout } from 'src/services/api.service'
import { BigNumber, utils } from 'ethers'
import { Account } from 'src/Account'
import { Token } from 'src/Token'
// import { SetPublicMintTime } from 'src/SetPublicMintTime'
// import { SetPreMintTime } from 'src/SetPreMintTime'

function App() {
  const [ network, setNetwork ] = useState('')
  const [ gasWei, setGasWei ] = useState(BigNumber.from(0))
  const [ account, setAccount ] = useState('')

  const updateNetwork = (chainId: string | number) => {
    switch (chainId) {
      case '0x89':
        setNetwork('폴리곤 메인넷')
        break
      default:
        setNetwork(`네트워크 Chain ID: ${ chainId }`)
        break
    }
  }

  let init = false
  useEffect(() => {
    if (init) return
    init = true

    setTimeout(async () => {
      const wallet = getWallet()

      await wallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      })

      const provider = new Web3Provider(wallet)
      const signer = provider.getSigner()

      updateNetwork(`0x${ (await provider.getNetwork()).chainId }`)
      wallet.on('chainChanged', (chainId: string) => {
        updateNetwork(chainId)
      })

      setInterval(async () => {
        const gasWei = await provider.getGasPrice()
        setGasWei(gasWei)
      }, 5000)

      try {
        const address = await signer.getAddress()
        if (!address) {
          logout()
          return
        }
      } catch (e) {
        logout()
        return
      }
    })
  }, [])

  return (
    <div className="bg-aaa-background">
      <div className={ 'container flex flex-col justify-center items-center' }>
        <div className={ 'py-20 text-center' }>
          <h1 className={ 'text-3xl' }>STOY ERC-20 관리페이지</h1>
        </div>
        <div className={ 'space-y-10' }>
          { network ? (
            <div className={ 'text-center pb-8 ' }>
              <p className={ '' }>연결된 네트워크: [{ network }]</p>
              <p className={ '' }>
                { gasWei.gt(0)
                  ? `현재 가스 가격: ${ utils.formatUnits(gasWei, 'gwei') } gwei (${ utils.formatEther(gasWei) } ETH)`
                  : '현재 가스 가격: 조회중'
                }
              </p>
            </div>
          ) : null }

          <Token network={ network } />
          <Account network={ network } />

        </div>
        <div className={ 'py-20 text-center' }>
          <h1 className={ 'text-2xl' }><span className={ 'text-lg' }>Made by</span> DARKO Studio<sup>&reg;</sup></h1>
          <address className={ 'py-2 text-left not-italic font-thin' }>
            help@darko.studio
          </address>
        </div>
      </div>
    </div>
  )
}

export default App

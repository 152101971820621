import { getWallet } from 'src/services/wallet.service'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, Contract } from 'ethers'

const getContract = (contractName: string, address: string) => {
  const wallet = getWallet()
  const provider = new Web3Provider(wallet)
  const signer = provider.getSigner()

  const artifact = require(`../resources/${ contractName }.json`)
  return new Contract(address, artifact.abi, signer)
}

export const toUnit = (minimalUnits: BigNumber, decimals: number) => {
  const decimalFraction = (10 ** decimals).toString()
  return minimalUnits.div(decimalFraction).toNumber()
}

export const toMinimalUnit = (units: string, decimals: number | undefined) => {
  return Number(units) * (10 ** (decimals ?? 18))
}

export const STOY = () => getContract('STOY', '0xb292B3aB38883CFdb70B7514Ab58f6d1D8297d40')
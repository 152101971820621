import React, { useEffect, useState } from 'react'
import { BigNumber, Contract } from 'ethers'
import { STOY, toUnit } from 'src/services/contract.service'

export const Token: React.FC<{ network: string }>
  = ({ network }) => {

  const [ contract, setContract ] = useState<Contract>()
  const [ name, setName ] = useState('')
  const [ symbol, setSymbol ] = useState('')
  const [ totalSupply, setTotalSupply ] = useState<number>(NaN)

  useEffect(() => {
    try {
      const contract = STOY()
      setContract(contract)
    } catch (e) {
      console.error(e)
    }
  }, [ network ])

  useEffect(() => {
    if (contract) {
      setTimeout(async () => {
        setName(await contract.name())
        setSymbol(await contract.symbol())
        const total: BigNumber = await contract.totalSupply()
        const decimals = await contract.decimals()
        const totalNumber = toUnit(total, decimals)
        setTotalSupply(totalNumber)
      })
    }
  }, [ contract ])

  //           {'balanceOf'}
  //
  //           {'isPaused'}
  //           {'pauseAccount'}
  //           {'unpauseAccount'}
  //
  //           {'lockedtokenNameOf'}
  //           {'lock'}
  //           {'unlock'}
  //
  //           {'timeLockInfoOf'}
  //           {'timeLock'}
  //           {'deleteTimeLock'}

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">STOY 정보</h3>
            </div>
          </div>
          <div className="mt-5">
            <div className="overflow-hidden shadow rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <p>{ name } ({ symbol })</p>
                <p>총 발행량 : { Intl.NumberFormat().format(totalSupply) } {symbol}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
